<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="branchModel" :disabled="isUpdate" :clearable="false"
                                  :options="branchOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="branch">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>


                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Chuỗi mã hóa QR" v-model="qrCodeInfo.qrCode"
                                  v-validate="'required'" name="qrCode"/>
                        <span class="text-danger text-sm">{{ errors.first('qrCode') }}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 w-full mt-10">
                        <vs-checkbox v-model="qrCodeInfo.status" class="mb-1">Trạng thái hoạt động</vs-checkbox>
                    </div>
                </div>

                <div class="vx-row" v-if="qrCodeInfo.qrCode">
                    <div class="vx-col md:w-1/3 w-full mt-5">
                        <label class="vs-input--label">Mã QR</label>
                        <div id="qr-code" class="mt-2 ml-16">
                            <div ref="capture" class="qr-code-render">
                                <VueQRCodeComponent :text="qrCodeInfo.qrCode" error-level="H"></VueQRCodeComponent>
                            </div>
                            <vs-button size="small" class="btn-download-qr mt-2" @click="exportQRCode"
                                       :disabled="!validateForm">Xuất file
                            </vs-button>
                        </div>
                    </div>
                </div>

                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="wifiList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
    components: {
        vSelect,
        VueQRCodeComponent,
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            branchOptions: [],
            qrCodeInfo: {},
            branchId: null,
            qrId: null,
            value: 'https://example.com',
            size: 300,
        }
    },
    created() {
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');

        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.branchId = this.$route.query.branch_id;
        this.qrId = this.$route.query.id;

        if (!this.qrId)
            return this.qrCodeInfo = {
                qrCode: '',
                status: 1,
                streamUrl: '',
                organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
            };

        this.$vs.loading();
        this.$crm.get(`/qr-code/find-by-id/${this.qrId}`)
            .then((response) => {
                this.$vs.loading.close();
                if (response.data) {
                    this.isUpdate = true;
                    this.qrCodeInfo = response.data;
                }
            })
            .catch(() => {
                this.$vs.loading.close();
                this.qrCodeInfo = {
                    qrCode: '',
                    status: 1,
                    organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
                }
            });
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.qrCodeInfo.organizationBranchId)),
                    value: this.qrCodeInfo.organizationBranchId
                }
            },
            set(obj) {
                this.qrCodeInfo.organizationBranchId = obj.value
            }
        },
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        exportQRCode() {
            let src = document.getElementById('qr-code').getElementsByTagName('img')[0].src;
            this.downloadBase64File(src, "QR-Code");
        },
        downloadBase64File(contentBase64, fileName) {
            let downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);
            downloadLink.href = contentBase64;
            downloadLink.target = '_self';
            downloadLink.download = fileName;
            downloadLink.click();
        },
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;

                let url = this.isUpdate ? `/qr-code/update/${this.qrCodeInfo.id}` : `/qr-code/create/${this.qrCodeInfo.organizationBranchId}`,
                    data = this.qrCodeInfo;
                data.status = data.status ? 1 : 0;
                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} mã QR thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$vs.loading.close();
                    this.$router.push(`/user/manage-qr-code${this.branchId ? `?branch_id=${this.branchId}` : ''}`).catch(() => {
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        wifiList() {
            this.$router.push(`/user/manage-qr-code${this.branchId ? `?branch_id=${this.branchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>

.qr-code-render {
    float: left;
}

.btn-download-qr {
    margin-left: 15px;
}
</style>
